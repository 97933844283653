import React from 'react';
import PropTypes from 'prop-types';
import M from '@materializecss/materialize';

import { useHistory, useParams } from 'react-router-dom';
import { reducer, sendRequest } from '../utils';

import ConnectedDevices from './ConnectedDevices';

function calculateImperialBMI(patient) {
  // Weight should be in lbs
  const weight = parseFloat(patient.patient.patient_profile.weight_in_lbs);

  // Height should be in inches
  // Raw height is in feets like 17.5
  const rawHeight = patient.patient.patient_profile.height_in_ft;
  const ft = parseInt(rawHeight, 10);
  const inch = Math.round((rawHeight - ft) * 12);
  const height = ft * 12 + inch;

  const BMI = (weight / (height * height)) * 703;
  let roundedBMI = Math.floor(BMI);
  let diff = Math.round((BMI - roundedBMI) * 10);

  if (diff === 10) {
    roundedBMI += 1;
    diff = 0;
  }

  return `${roundedBMI}.${diff}`;
}

const CommonInfo = ({ patient }) => {
  const history = useHistory();
  const { patientId } = useParams();
  const modalRemoveConnectionConfirm = React.useRef(null);

  const [state, dispatch] = React.useReducer(
    reducer,
    {
      data: null, message: '', error: '', isLoading: false, isError: false,
    },
  );

  React.useEffect(() => {
    if (modalRemoveConnectionConfirm.current) {
      M.Modal.init(modalRemoveConnectionConfirm.current, {});
    }
  });

  const handleHighRiskChange = (e) => {
    dispatch({ type: 'FETCH_INIT' });

    const value = e.target.checked;

    sendRequest(`connections/patients/${patientId}`, 'PUT', { needs_attention: value })
      .then((response) => {
        if (response.status === 'error') {
          dispatch({ type: 'FETCH_FAILURE', error: response.message });
        } else {
          dispatch({ type: 'FETCH_SUCCESS', message: response.message });
        }
      })
      .catch(() => {
        dispatch({ type: 'FETCH_FAILURE', error: 'Something went wrong...' });
      });
  };

  const handleRemoveConnection = () => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest(`connections/patients/${patient.patient.id}`, 'DELETE')
      .then((response) => {
        if (response.status === 'error') {
          dispatch({ type: 'FETCH_FAILURE', error: response.message });
        } else {
          history.replace('/');
          dispatch({ type: 'FETCH_SUCCESS', message: response.message });
        }
      })
      .catch(() => {
        dispatch({ type: 'FETCH_FAILURE', error: 'Something went wrong...' });
      });
  };

  return (
    <div>
      <h5 className="header-with-button mt4 mb4">
        Common Info
        <a href={`/patients/${patient.patient.id}/edit`} className="btn btn-small white black-text right hide-on-print">Edit</a>
        <button type="button" data-target="remove-modal" className="btn btn-small white red-text right hide-on-print mr2 modal-trigger">
          Remove
        </button>
      </h5>

      <div className={`form-messages ${state.message ? '' : 'hide'}`}>{state.message}</div>
      <div className={`form-errors ${state.error ? '' : 'hide'}`}>{state.error}</div>

      <table>
        <tbody>
          <tr>
            <td><b>First Name</b></td>
            <td>{patient.patient.first_name}</td>
          </tr>
          <tr>
            <td><b>Last Name</b></td>
            <td>{patient.patient.last_name || ''}</td>
          </tr>
          <tr>
            <td><b>Phone Number</b></td>
            <td>{patient.patient.patient_profile.phone_number}</td>
          </tr>
          <tr>
            <td><b>Email</b></td>
            <td>{patient.patient.email}</td>
          </tr>
          <tr>
            <td><b>Gender</b></td>
            <td>{patient.patient.patient_profile.gender === 'F' ? 'Female' : 'Male'}</td>
          </tr>
          <tr>
            <td><b>Date of Birth</b></td>
            <td>{patient.patient.patient_profile.date_of_birth}</td>
          </tr>

          <tr>
            <td><b>Height</b></td>
            <td>
              {parseFloat(patient.patient.patient_profile.height_in_ft || '0.0').toFixed(2)}
              {' '}
              ft
            </td>
          </tr>
          <tr>
            <td><b>Weight</b></td>
            <td>
              {parseFloat(patient.patient.patient_profile.weight_in_lbs || '0.0').toFixed(2)}
              {' '}
              lbs
            </td>
          </tr>
          <tr>
            <td><b>BMI</b></td>
            <td>
              {`${calculateImperialBMI(patient)}`}
            </td>
          </tr>
          <tr>
            <td><b>Needs Attention</b></td>
            <td>
              <div className="switch high-risk-switch">
                <label>
                  No
                  <input
                    name="high_risk"
                    type="checkbox"
                    defaultChecked={patient.patient.patient_profile.needs_attention}
                    onChange={handleHighRiskChange}
                  />
                  <span className="lever" />
                  Yes
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td><b>Chronic Conditions</b></td>
            <td>
              {
                patient.patient.patient_profile.chronic_conditions
                  ?.filter((cc) => cc !== '')
                  .map((cc) => (
                    <span key={cc} className="chip">
                      {cc}
                    </span>
                  ))
              }
            </td>
          </tr>
          <tr>
            <td><b>Medication</b></td>
            <td className="wrap-newlines">
              {
              patient.patient.patient_profile.medications
                ?.filter((m) => m !== '')
                .map((m) => (
                  <span key={m} className="chip">
                    {m}
                  </span>
                ))
              }
            </td>
          </tr>
          <tr>
            <td><b>Conditions</b></td>
            <td className="wrap-newlines">
              {
                patient.patient.patient_profile.conditions
                  ?.filter((c) => c !== '')
                  .map((c) => (
                    <span key={c} className="chip">
                      {c}
                    </span>
                  ))
              }
            </td>
          </tr>

          <tr>
            <td><b>Emergency Contact</b></td>
            <td className="wrap-newlines">
              <dl>
                <strong>Name: </strong>
                {patient.patient.patient_profile.emergency_contact_name}
                <br />

                <strong>Phone: </strong>
                {patient.patient.patient_profile.emergency_contact_phone}
                <br />

                <strong>Email: </strong>
                {patient.patient.patient_profile.emergency_contact_email}
                <br />

                <strong>Relationship: </strong>
                {patient.patient.patient_profile.emergency_contact_relationship}
              </dl>
            </td>
          </tr>
        </tbody>
      </table>

      <ConnectedDevices patientId={patient.patient.id} />

      {/* Modals */}
      <div ref={modalRemoveConnectionConfirm} id="remove-modal" className="modal">
        <div className="modal-content">
          <h5>Remove Connection</h5>
          <p>Are you sure you want to remove this patient from the patient dashboard?</p>
        </div>
        <div className="modal-footer">
          <a href="#!" className="modal-close waves-effect btn-flat" onClick={handleRemoveConnection}>Yes</a>
          <a href="#!" className="modal-close waves-effect btn-flat">No</a>
        </div>
      </div>

    </div>
  );
};

CommonInfo.propTypes = {
  patient: PropTypes.shape({
    patient: PropTypes.shape({
      id: PropTypes.string.isRequired,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string,
      email: PropTypes.string.isRequired,
      patient_profile: PropTypes.shape({
        chronic_conditions: PropTypes.arrayOf(PropTypes.string),
        conditions: PropTypes.arrayOf(PropTypes.string),
        medications: PropTypes.arrayOf(PropTypes.string),
        surgeries_treatments: PropTypes.arrayOf(PropTypes.string),
        phone_number: PropTypes.string.isRequired,
        gender: PropTypes.string,
        height_in_ft: PropTypes.number.isRequired,
        weight_in_lbs: PropTypes.number.isRequired,
        date_of_birth: PropTypes.string,
        needs_attention: PropTypes.bool.isRequired,
        emergency_contact_name: PropTypes.string,
        emergency_contact_phone: PropTypes.string,
        emergency_contact_email: PropTypes.string,
        emergency_contact_relationship: PropTypes.string,
      }),
    }).isRequired,
  }).isRequired,
};

export default CommonInfo;
