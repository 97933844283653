import React from 'react';
import Plot from 'react-plotly.js';
import PropTypes from 'prop-types';

import M from '@materializecss/materialize';
import moment from 'moment';
import NoData from '../../Components/NoData';
import Table from '../../Components/Table';
import { reducer, round, sendRequest } from '../../utils';
import Loader from '../../Components/Loader';
import Select from '../../Components/Select';
import { ENTRY_TYPES_LABELS } from './constants';

const VitalColumn = ({ row }) => {
  // eslint-disable-next-line camelcase
  const { entry_type } = row.original;
  return <span>{ENTRY_TYPES_LABELS[entry_type]}</span>;
};

VitalColumn.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      entry_type: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
};

const EntryActions = ({ row: { original: cell } }) => {
  const modalEl = React.useRef(null);
  const [plot, setPlot] = React.useState([]);

  React.useEffect(() => {
    if (modalEl.current && plot) {
      M.Modal.init(modalEl.current, {});
    }
  });

  React.useEffect(() => {
    // Generate plot data for ECG
    if (cell.original_entry_type === 5) {
      const y = Array.isArray(cell.ecg_data) ? cell.ecg_data : JSON.parse(cell.ecg_data);
      const x = Array.from(Array(y.length).keys());

      const plot = [
        {
          x,
          y,
          type: 'scatter',
          mode: 'lines',
          marker: {
            color: '#f44336',
          },
          name: 'ECG',
        },
      ];

      setPlot(plot);
    }
  }, [cell]);

  // This is needed to resize graph in modal window
  const onModalShow = () => window.dispatchEvent(new Event('resize'));

  // For now we have actions only for ECG
  if (cell.original_entry_type === 5) {
    const minValue = Math.min(...plot[0]?.y || []);
    const maxValue = Math.max(...plot[0]?.y || []);
    const mediumValue = round((minValue + maxValue) / 2);

    return (
      <div>
        <a
          className="waves-effect waves-light btn black modal-trigger"
          href={`#modal-${cell.id}`}
          onClick={onModalShow}
        >
          View ECG
        </a>

        <div id={`modal-${cell.id}`} className="modal modal-fixed-footer" ref={modalEl}>
          <div className="modal-content">
            <Plot
              data={plot}
              layout={{
                legend: { x: 0.01, y: -0.5, orientation: 'h' },
                margin: {
                  l: 60, r: 20, b: 50, t: 50, pad: 2,
                },
                autosize: true,
                yaxis: {
                  title: 'ECG',
                  nticks: 3,
                  tickformat: 'd',
                  tickvals: [minValue, mediumValue, maxValue],
                },
                xaxis: {
                  title: 'Time',
                },
                paper_bgcolor: 'rgba(0,0,0,0)',
                plot_bgcolor: 'rgba(0,0,0,0)',
              }}
              config={{
                displayModeBar: false,
                responsive: true,
              }}
            />

          </div>
          <div className="modal-footer">
            <a href="#!" className="modal-close waves-effect btn-flat">Close</a>
          </div>
        </div>
      </div>
    );
  }
  return <></>;
};

EntryActions.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
      original_entry_type: PropTypes.string.isRequired,
      ecg_data: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

const ENTRY2COLUMNS = {
  0: ['id', 'created_at', 'entry_type', 'systolic', 'diastolic', 'spo2', 'pulse', 'weight', 'temperature', 'bgm'],
  1: ['id', 'created_at', 'systolic', 'diastolic', 'pulse'],
  2: ['id', 'created_at', 'spo2', 'pulse'],
  3: ['id', 'created_at', 'weight'],
  4: ['id', 'created_at', 'glucose'],
  5: ['id', 'created_at', 'pulse', 'actions'],
  12: ['id', 'created_at', 'temperature'],
};

const COLUMNS = [
  {
    Header: 'Date',
    accessor: 'created_at',
    label: 'Date',
  },
  {
    Header: 'ECG',
    accessor: 'ecg_data',
    label: 'ECG',
  },
  {
    Header: 'Vital',
    accessor: 'entry_type',
    label: 'Vital',
    Cell: VitalColumn,
  },
  {
    Header: 'Systolic',
    accessor: 'systolic',
    label: 'Systolic',
  },
  {
    Header: 'Diastolic',
    accessor: 'diastolic',
    label: 'Diastolic',
  },
  {
    Header: () => (
      <>
        SpO
        <sub>2</sub>
      </>
    ),
    accessor: 'spo2',
    label: 'SpO2',
  },
  {
    Header: 'Pulse',
    accessor: 'pulse',
    label: 'Pulse',
  },
  {
    Header: 'Weight',
    accessor: 'weight',
    label: 'Weight',
  },
  {
    Header: 'Temperature',
    accessor: 'temperature',
    label: 'Temperature',
  },
  {
    Header: 'Glucose',
    accessor: 'glucose',
    label: 'Glucose',
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    label: 'Actions',
    Cell: EntryActions,
    disableSortBy: true,
  },
];

const PatientEntries = ({
  patient, startDate, endDate, isActive, withDownloadButton, withPagination,
}) => {
  const [state, dispatch] = React.useReducer(
    reducer,
    { data: [], isLoading: true, isError: false },
  );
  const [data, setData] = React.useState([]);
  const [filterOptions, setFilterOptions] = React.useState([]);
  const [filter, setFilter] = React.useState(0);

  React.useEffect(() => {
    dispatch({ type: 'FETCH_INIT' });

    if (!isActive) {
      // Do not load anything while not active
      return;
    }

    const opts = {
      from_date: moment(startDate).utc().format(),
      to_date: moment(endDate).utc().format(),
    };

    sendRequest(
      `connections/patients/${patient.patient.id}/entries`, 'GET',
      { ...opts },
    ).then((response) => {
      if (response.status === 'error') {
        dispatch({
          type: 'FETCH_FAILURE',
          error: response.message,
          payload: [],
        });
      } else {
        const rawData = response.data;

        rawData
          .map((i) => ({
            ...i,
            created_at: moment(i.created_at),
          }))
          .sort((a, b) => {
            if (a.created_at > b.created_at) {
              return 1;
            }
            if (a.created_at < b.created_at) {
              return -1;
            }
            return 0;
          });

        // Filter options based on entries returned with API
        const entryTypes = [...new Set(rawData.map((e) => e.entry_type))];
        const filterOptions = Object.fromEntries(
          [[0, 'All'], ...Object.entries(ENTRY_TYPES_LABELS).filter((et) => entryTypes.includes(Number(et[0])))],
        );

        dispatch({ type: 'FETCH_SUCCESS', payload: rawData });
        setData(rawData);
        setFilterOptions(filterOptions);
      }
      setFilter(0);
    })
      .catch(() => {
        dispatch({ type: 'FETCH_FAILURE', error: 'Something went wrong...' });
      });
  }, [startDate, endDate, isActive]);

  React.useEffect(() => {
    if (filter) {
      setData(state.data.filter((i) => i.entry_type === filter));
    } else {
      setData(state.data);
    }
  }, [filter]);

  if (state.isLoading) {
    return (
      <Loader />
    );
  }

  if (!data.length) {
    return (
      <NoData />
    );
  }

  const entries = data.map(
    (e) => ({
      id: e.id,
      entry_type: e.entry_type,
      // We use additional attribute because entry_type will be replaced with user friendly value
      original_entry_type: e.entry_type,
      created_at: moment.utc(e.created_at).local().format('MMM D, YYYY h:mm A'),
      created_at_raw: moment.utc(e.created_at).local(),
      systolic: e.systolic,
      diastolic: e.diastolic,
      spo2: e.spo2,
      pulse: e.pulse,
      weight: e.weight ? `${e.weight} lbs` : '',
      ecg_data: e.ecg_data,
      temperature: e.temperature ? `${e.temperature} °F` : '',
      glucose: e.glucose ? `${e.glucose} mg/dL` : '',
    }),
  );

  const columns = COLUMNS.filter((e) => ENTRY2COLUMNS[filter].includes(e.accessor));

  return (
    <>
      <div className="input-field col s12 m6 hide-on-print">
        <Select
          name="entry_type"
          label="Vital"
          values={filterOptions}
          defaultValue=""
          onChange={(e) => setFilter(Number(e.target.value))}
        />
      </div>
      <Table
        columns={columns}
        data={entries}
        withDownloadButton={withDownloadButton}
        centered
        defaultSortBy="created_at"
        defaultSortDesc
        downloadFilename={`${patient.patient.first_name}_${patient.patient.last_name || ''}_entries.csv`}
        defaultPageSize={withPagination ? 25 : entries.length}
      />
    </>
  );
};

PatientEntries.propTypes = {
  patient: PropTypes.shape({
    patient: PropTypes.shape({
      id: PropTypes.string.isRequired,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      patient_profile: PropTypes.shape({
        weight_in_lbs: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
    updated_at: PropTypes.string.isRequired,
  }).isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  isActive: PropTypes.bool.isRequired,
  withDownloadButton: PropTypes.bool.isRequired,
  withPagination: PropTypes.bool,
};

PatientEntries.defaultProps = {
  withPagination: true,
};

export default PatientEntries;
